<template>
  <div>
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold;font-size: 16px;line-height: normal;">明细详情</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div style="padding: 20px;">
      <van-row v-for="(item, index) in detailedlist" :key="index" style="margin-bottom: 20px;">
        <van-col span="24" style="border: 1px gainsboro solid;padding: 20px;border-radius: 10px;">
          <van-row>
            <van-col span="18" style="font-size: 16px;">{{ item.note }}</van-col>
            <van-col span="6" style="font-size: 16px;color: rgba(55, 159, 0, 1);">{{ item.money }}</van-col>
          </van-row>
          <van-row style="margin-top: 10px;">
            <van-col span="24" style="font-size: 12px;">{{ item.create_time }}</van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { detailed } from "@/utils/api.js";
const page = ref(1)
const page_size = ref(50)
const detailedlist = ref([])
const time = ref([])
const times = ref([])
const onClickLeft = () => history.back();
getlist(page.value)
function getlist(pagnub) {
  detailed({ page: pagnub, page_size: page_size.value,score_category:3 }).then(async (res) => {
    if ((await res.code) === 200) {
      console.log(res, 'rr');
      detailedlist.value = res.data.list
      // detailedlist.value.create_time.forEach((v) => {
      //   time.value.push(v);
      //   });
      console.log(time.value);
    }
  });
}
// times.value = formatDate(time)
const formatDate = (value, spe = '/') => {
  value = value * 1000
  let data = new Date(value)
  let year = data.getFullYear()
  let month = data.getMonth() + 1
  let day = data.getDate()
  let h = data.getHours()
  let mm = data.getMinutes()
  let s = data.getSeconds()
  month = month >= 10 ? month : '0' + month
  day = day >= 10 ? day : '0' + day
  h = h >= 10 ? h : '0' + h
  mm = mm >= 10 ? mm : '0' + mm
  s = s >= 10 ? s : '0' + s
  return `${year}${spe}${month}${spe}${day} ${h}:${mm}:${s}`
}
</script>

<style lang="scss" scoped>
.van-nav-bar__content {
  height: 1rem !important;
}
::v-deep .van-nav-bar__arrow {
      font-size: 46px !important;
      padding-top: 10px;
      color: #000;
}
::v-deep .van-nav-bar__title {
  font-weight: bold !important;
  font-size: 30px !important;
  height: 80px;
}

::v-deep .van-nav-bar__content {
  padding-top: 30px;
  height: 80px;
  // margin: auto;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
}

.van-dropdown-menu__bar {
  box-shadow: none !important;
}

.van-nav-bar__content {
  box-shadow: none !important;
}

.van-hairline--bottom:after {
  border-bottom-width: 0 !important;
}

.van-collapse-item {
  border: 1px red solid;
}

.van-popup {
  // position: absolute !important;
}

.van-dropdown-menu__title {
  line-height: normal !important;
}

.van-dropdown-menu__bar {
  height: 60px !important;
}

.van-nav-bar__left {
  padding: 0rem 0.3rem 0rem 0.3rem !important;
}

.van-ellipsis {
  font-weight: bold !important;
  overflow: visible;
}
</style>
